import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agents";
import { Filters } from "../models/filter";
import { Role } from "../models/role";
import { toast } from 'react-toastify';

export default class RoleStore {
    filteredRoles: Role[] = [];
    roles: Role[] = [];
    role: Role | undefined = undefined;
    total: number = 0;
    loadingInitial = false;
    isLoading = false;
    filterValues: Filters = { pageNumber: 1, pageSize: 10, searchKeyword: "" };
    editMode = false;

    constructor() {
        makeAutoObservable(this);
    }

    get pageNumber() {
        return this.filterValues.pageNumber;
    }

    getAllRoles = async () => {
        this.setLoading(true);

        if (this.roles.length > 0) {
            this.setLoading(false);
            return this.roles;
        }

        try {
            const data = await agent.Roles.getAll();
            this.setLoading(false);
            return data.roles;
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    filterRoles = async (initialLoad: boolean = false) => {
        if (initialLoad) this.setLoadingInitial(true);
        this.setLoading(true);
        try {
            const { total, roles } = await agent.Roles.filter({
                pageNumber: this.filterValues.pageNumber,
                pageSize: this.filterValues.pageSize,
                searchKeyword: this.filterValues.searchKeyword
            });

            runInAction(() => {
                this.filteredRoles = roles;
                this.total = total;
            });

            this.setLoadingInitial(false);
            this.setLoading(false);
        } catch (error) {
            console.error(error);
            this.setLoadingInitial(false);
            this.setLoading(false);
        }
    }

    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }

    setLoading = (state: boolean) => {
        this.isLoading = state;
    }

    setPageNumber = (pageNumber: number) => {
        this.filterValues = { ...this.filterValues, pageNumber: pageNumber };
        this.filterRoles();
    }

    setSearchQuery = (query: string) => {
        this.filterValues = { ...this.filterValues, pageNumber: 1, searchKeyword: query };
        this.filterRoles();
    }

    loadRole = async (id: string) => {
        this.loadingInitial = true;

        try {
            const role = await agent.Roles.details(id);
            this.setLoadingInitial(false);
            this.role = role;
            return role
        } catch (error) {
            console.log(error);
            this.setLoadingInitial(false);
        }
    }

    createRole = async (role: Role) => {
        this.isLoading = true;

        try {
            await agent.Roles.create(role);
            runInAction(() => {
                this.editMode = false;
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    updateRole = async (role: Role) => {
        this.isLoading = true;

        try {
            await agent.Roles.update(role);
            runInAction(() => {
                this.editMode = false;
                this.isLoading = false;
            });
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };

    deleteRole = async (id: string) => {
        this.isLoading = true;

        try {
            await agent.Roles.delete(id);
            runInAction(() => {
                this.filteredRoles = [...this.filteredRoles.filter(r => r.id != id)];
                this.editMode = false;
                this.isLoading = false;
                toast.success("Role is successfully deleted.");
            });
        } catch (error: any) {

            if (error.response && error.response.data) {
                toast.error(error.response.data);
            }
            else {
                toast.error("Unable to delete this role.");
            }
            runInAction(() => {
                this.isLoading = false;
            })
        }
    };
}